<template>
  <v-container>
    <v-row >
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text">
            <v-toolbar-title>Share Your Feedback With Us</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row >
              <v-col>
                <h2>We would love to hear your thoughts, concerns or problems with anything so we can improve for you!</h2>
              </v-col>
            </v-row>
            <v-row >
              <v-col>
                <v-text-field
                  label="Your name*"
                  v-model="name"
                  required
                  :rules="[
                    () => $v.name.required || 'A name is required'
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row >
              <v-col>
                <v-text-field
                  label="Your email address*"
                  v-model="email"
                  required
                  :rules="[
                    () => $v.email.required || 'An email is required',
                    () => $v.email.email || 'A valid email is required'
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row >
              <v-col>
                <v-text-field
                  label="Subject*"
                  v-model="subject"
                  required
                  :rules="[
                    () => $v.subject.required || 'A subject is required'
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row >
              <v-col>
                <v-textarea
                  label="Message*"
                  v-model="message"
                  required
                  :rules="[
                    () => $v.message.required || 'A message is required'
                  ]"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row dense justify="center">
              <v-btn
                color="color3 color3Text--text"
                :disabled="!valid"
                @click="submit"
              >
                Submit Feedback
              </v-btn>
              <v-btn
                color="color3Text color3--text"
                @click="reset"
              >Reset</v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import SDK from '@/VBL'

export default {
  mixins: [validationMixin],
  validations () {
    return {
      name: { required },
      email: { required, email },
      subject: { required },
      message: { required }
    }
  },
  data () {
    return {
      name: null,
      email: null,
      subject: null,
      message: null,
      thanks: true
    }
  },
  computed: {
    valid () { return !this.$v.$invalid }
  },
  methods: {
    submit () {
      const sdk = new SDK(this.axios)
      sdk.putFeedback({
        name: this.name,
        email: this.email,
        subject: this.subject,
        message: this.message
      }).then((response) => {
        this.reset()
        this.$router.push({ name: 'feedback-thanks' })
      })
        .catch((error) => {
          console.log(error)
        })
    },
    reset () {
      this.name = null
      this.email = null
      this.subject = null
      this.message = null
      this.$v.name.$reset()
    }
  }
}
</script>

<style>

</style>
