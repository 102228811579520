var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"color1 color1Text--text"}},[_c('v-toolbar-title',[_vm._v("Share Your Feedback With Us")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('h2',[_vm._v("We would love to hear your thoughts, concerns or problems with anything so we can improve for you!")])])],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Your name*","required":"","rules":[
                  function () { return _vm.$v.name.required || 'A name is required'; }
                ]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Your email address*","required":"","rules":[
                  function () { return _vm.$v.email.required || 'An email is required'; },
                  function () { return _vm.$v.email.email || 'A valid email is required'; }
                ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Subject*","required":"","rules":[
                  function () { return _vm.$v.subject.required || 'A subject is required'; }
                ]},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Message*","required":"","rules":[
                  function () { return _vm.$v.message.required || 'A message is required'; }
                ]},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1),_c('v-row',{attrs:{"dense":"","justify":"center"}},[_c('v-btn',{attrs:{"color":"color3 color3Text--text","disabled":!_vm.valid},on:{"click":_vm.submit}},[_vm._v(" Submit Feedback ")]),_c('v-btn',{attrs:{"color":"color3Text color3--text"},on:{"click":_vm.reset}},[_vm._v("Reset")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }